import React from "react";

const ListView = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-10 w-10"}
      fill="none"
      viewBox="0 0 41 41"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.98 6.406c-.708 0-1.282.574-1.282 1.282v25.625c0 .707.574 1.28 1.281 1.28h29.042c.707 0 1.281-.573 1.281-1.28V7.686c0-.707-.574-1.28-1.281-1.28H5.979zM2.134 7.688A3.844 3.844 0 015.98 3.844h29.042a3.844 3.844 0 013.844 3.844v25.625a3.844 3.844 0 01-3.844 3.843H5.979a3.844 3.844 0 01-3.844-3.843V7.686z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.135 11.958c0-.707.574-1.28 1.282-1.28h34.166a1.281 1.281 0 110 2.562H3.417a1.281 1.281 0 01-1.282-1.282zM15.802 20.5c0-.708.574-1.281 1.281-1.281H30.75a1.281 1.281 0 110 2.562H17.083a1.281 1.281 0 01-1.28-1.281zM15.802 27.333c0-.707.574-1.28 1.281-1.28H30.75a1.281 1.281 0 110 2.562H17.083a1.281 1.281 0 01-1.28-1.282zM8.969 20.5c0-.708.573-1.281 1.281-1.281h1.708a1.281 1.281 0 110 2.562H10.25A1.281 1.281 0 018.969 20.5zM8.969 27.333c0-.707.573-1.28 1.281-1.28h1.708a1.281 1.281 0 110 2.562H10.25a1.281 1.281 0 01-1.281-1.282z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ListView;
