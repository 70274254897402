import React from "react";

const QuestionMarkCircle = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "h-8 w-8"}
      fill="none"
      viewBox="0 0 30 30"
      {...rest}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10.285 11.25c.686-1.456 2.538-2.5 4.715-2.5 2.762 0 5 1.679 5 3.75 0 1.75-1.597 3.219-3.757 3.633-.678.13-1.243.677-1.243 1.367m0 3.75h.012M26.25 15c0 6.213-5.037 11.25-11.25 11.25S3.75 21.213 3.75 15 8.787 3.75 15 3.75 26.25 8.787 26.25 15z"
      ></path>
    </svg>
  );
};

export default QuestionMarkCircle;
