import React from "react";

const Check = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 41 41"
      className={className ? className : "h-10 w-10"}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M37.637 8.492c.5.502.498 1.313-.004 1.812L15.318 32.512c-.5.498-1.308.498-1.808 0L3.367 22.418A1.281 1.281 0 115.175 20.6l9.24 9.196 21.41-21.31a1.281 1.281 0 011.812.005z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Check;
