import React, { useState } from "react";
import homePageOne from "../../assets/images/homepageOne.png";
import homePageTwo from "../../assets/images/homepageTwo.png";
import homePageThree from "../../assets/images/homepageThree.png";
import homePageFour from "../../assets/images/homepageFour.png";
import Button from "../../components/Button/Button";
import wavyLinesBackground from "../../assets/images/wavyLinesBackground.png";
import ListView from "../../assets/SVG/list-view";
import Clock from "../../assets/SVG/clock";
import homePageFive from "../../assets/images/homepageFive.png";
import Check from "../../assets/SVG/check";
import ReviewersCard from "../../components/ReviewersCard/ReviewersCard";
import Reviewer from "../../assets/images/reviewer.png";
import homePageSix from "../../assets/images/homepageSix.png";
import homePageSeven from "../../assets/images/homepageSeven.png";
import homePageEight from "../../assets/images/homePageEight.png";
import PreRegistrationModal from "../../components/PreRegisterationModal/PreRegisterationModal";

const HomePage = () => {
  const [openPreRegistrationModal, setOpenPreRegistrationModal] =
    useState(false);

  return (
    <div className="max-w-screen relative">
      {openPreRegistrationModal && (
        <PreRegistrationModal setOpen={setOpenPreRegistrationModal} />
      )}
      <div className="w-full h-[85vh] lg:h-full relative">
        <img
          src={homePageOne}
          alt=""
          className="w-full h-full object-cover lg:h-[660px]"
        />
        <div className="absolute left-0 top-0 right-0 lg:right-auto">
          <img
            src={homePageTwo}
            alt=""
            className="hidden lg:inline-flex w-full lg:w-[720px] lg:h-[660px]"
          />
          <img
            src={homePageEight}
            alt=""
            className="lg:hidden w-full h-[85vh] object-fill"
          />
          <div className="px-4 lg:px-0 w-full lg:w-[85%] absolute top-[10%] lg:top-[20%] left-1/2 transform -translate-x-1/2">
            <div className="text-white font-semibold text-2xl sm:text-4xl xl:text-5xl sm:leading-[50px] xl:leading-[60px]">
              <h1>Lighten the load of running</h1>
              <h1 className="underline underline-offset-[20px] decoration-[5px] decoration-primary-green">
                Your self-managed HOA
              </h1>
            </div>
            <div className="sm:max-w-lg tracking-wide text-white text-base sm:text-lg xl:text-xl xl:leading-[24px] font-light mt-10">
              <p>
                Training & resources to manage your HOA as good (if not better)
                than the pros. Build a better community without round-the-clock
                effort or draining your reserves.
              </p>
              <p className="mt-5">
                SMART launches <span className="font-bold">March 1, 2024</span>.
                Free 30-day limited-edition trial available for the first 100
                clients to pre-register. (No credit card required)
              </p>
            </div>
            <Button
              onClick={() => setOpenPreRegistrationModal(true)}
              innerText="PRE-REGISTER TODAY"
              extraClass="bg-primary-green text-primary-blue !text-base xl:!text-xl w-[75%] sm:w-[55%] md:w-[35%] xl:w-[55%] py-3 mt-5 xl:mt-10"
            />
          </div>
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${wavyLinesBackground})` }}
        className="max-w-screen flex flex-col items-center justify-center h-full bg-cover pt-5 sm:pt-10 3xl:pt-16 sm:px-10 xl:px-20 3xl:px-28"
      >
        <div>
          <h2 className="text-center w-[80%] mx-auto text-xl sm:text-2xl lg:text-3xl xl:text-4xl 3xl:text-6xl font-medium text-primary-blue xl:leading-[43px]">
            Navigate the challenges of self-managing your HOA{" "}
            <span className="text-primary-green font-bold">confidently</span>
          </h2>
        </div>

        <div className="w-full 3xl:w-[60%] mt-10 lg:mt-20 flex flex-col sm:flex-row sm:justify-center sm:space-x-10 xl:space-x-20 3xl:justify-between">
          <img
            src={homePageThree}
            alt=""
            className="w-full sm:w-1/2 sm:h-[230px] lg:h-[300px] 2xl:w-[600px] 2xl:h-[365px] bg-cover"
          />
          <div className="w-full sm:w-1/2 3xl:w-[600px] px-5 pt-10 sm:px-0 sm:py-0">
            <div className="flex items-center space-x-3 sm:space-x-5">
              <ListView className="w-8 h-8 xl:h-10 xl:w-10 text-primary-green" />
              <h3 className="text-xl lg:text-2xl 2xl:text-3xl text-primary-blue font-medium">
                Avoid common <br></br>legal and financial pitfalls
              </h3>
            </div>
            <hr className="border-t-[5px] border-primary-green mt-2" />
            <div className="text-base sm:text-xs lg:text-base 2xl:text-xl text-primary-blue font-light leading-[24px] mt-5">
              <p>
                Worried about next week's meeting on Directors & Officers
                insurance? Dive into a pre-recorded Zoom session with industry
                pros or read a white paper on the topic. We cover hundreds of
                essential topics like contracts, insurance, landscape
                maintenance, covenant enforcement and more.
              </p>
              <p className="mt-5 sm:mt-2 xl:mt-5">
                Use our dynamic search to quickly brush up and make important
                decisions confidently. Plus, discover how to trim costs on big
                contracts and negotiate discounts with top-tier vendors.
              </p>
            </div>
          </div>
        </div>

        <div className="w-full 3xl:w-[60%] mt-10 lg:mt-20 flex flex-col sm:flex-row sm:justify-center sm:space-x-10 xl:space-x-20 3xl:justify-between">
          <div className="order-2 sm:order-1 w-full sm:w-1/2 3xl:w-[600px] px-5 pt-10 sm:px-0 sm:py-0">
            <div className="flex items-center space-x-3 xl:space-x-5">
              <Clock className="h-8 w-8 xl:h-10 xl:w-10 text-primary-green" />
              <h3 className="text-xl lg:text-2xl 2xl:text-3xl text-primary-blue font-medium">
                Make the most out of your <br></br>time & efforts
              </h3>
            </div>
            <hr className="border-t-[5px] border-primary-green mt-2" />
            <div className="text-base sm:text-xs lg:text-base 2xl:text-xl text-primary-blue font-light leading-[24px] mt-5">
              <p>
                Ever felt lost looking through a sea of paperwork or thousands
                of Google responses? With SMART, you'll get your hands on the
                very forms and templates that top management companies use.
              </p>
              <p className="mt-5 sm:mt-2 xl:mt-5">
                From contracts to architectural applications, we've got you
                covered. No more late nights trying to "figure things out." It's
                not just about saving time; it's about making every moment as a
                volunteer HOA Board Member count.
              </p>
            </div>
          </div>
          <img
            src={homePageFour}
            alt=""
            className="order-1 sm:order-2 w-full sm:w-1/2 sm:h-[230px] lg:h-[300px] 2xl:w-[600px] 2xl:h-[365px] bg-cover"
          />
        </div>

        <div className="w-full 3xl:w-[60%] mt-10 xl:mt-20 h-full lg:h-[800px] flex flex-col lg:flex-row">
          <img
            src={homePageFive}
            alt=""
            className="h-[400px] lg:h-full object-cover"
          />
          <div className="mt-0.5 pl-4 py-5 xl:px-0 xl:py-10 xl:pl-8 xl:pr-16 3xl:pr-28 bg-primary-blue flex-grow rounded-br-[200px]">
            <div className="flex items-center space-x-3 mt-5">
              <h3 className="text-xl xl:text-3xl text-white font-medium leading-[36px]">
                Lean on industry experts
              </h3>
              <Check className="mt-1 xl:mt-0 h-8 w-8 xl:h-10 xl:w-10 text-primary-green" />
            </div>
            <hr className="mt-2 w-full rounded-l-full xl:rounded-none lg:w-[70%] border-t-[5px] border-primary-green" />
            <p className="text-white font-light text-base mr-5 xl:mr-0 xl:text-lg 2xl:text-xl my-5">
              Navigating the different aspects of an HOA can be tricky. We
              simplify it for you. Gain confidence in every decision, with
              seasoned pros from community association management, law,
              accounting, landscaping, and more. Imagine having complex
              insurance questions resolved by an industry expert through a
              previously recorded training seminar. With us, you gain a team of
              specialists, ensuring{" "}
              <span className="font-semibold">
                every decision you make is expert-backed
              </span>
              . If we do not have your question answered already, simply let us
              know and we will make it happen!
            </p>

            <p className="mr-3 xl:mr-0 text-xl xl:text-2xl 2xl:text-3xl text-white font-medium">
              <span className="text-primary-green font-semibold">
                Free Bonus:
              </span>{" "}
              Streamline violation enforcement
            </p>

            <p className="text-base xl:Text-lg 2xl:text-xl text-white font-light mr-5 xl:mr-0 my-5">
              Let us simplify a necessary yet unpleasant part of the job. We
              collaborated with a top-notch violation management software
              tailored for self-managed communities. Say goodbye to juggling
              Excel sheets and Word docs. Clients get{" "}
              <span className="font-semibold">3-months free </span>and a
              discounted rate of{" "}
              <span className="font-semibold">just $30 per month</span> for
              associations with less than 100 homes.  
            </p>

            <p className="mr-3 xl:mr-0 text-xl xl:text-2xl 2xl:text-3xl text-white font-medium">
              <span className="text-primary-green font-semibold">
                Second Free Bonus:
              </span>{" "}
              Free Consultation with an industry pro
            </p>

            <p className="text-base xl:text-lg 2xl:text-xl text-white font-light mr-16 xl:mr-0 my-5">
              When you have that next big meeting coming up and you just need to
              bounce a few things off an expert first, you receive a free
              30-minute consultation with a community management expert at
              TheHOACoach.com. No more walking into meetings unprepared, or
              finding yourself stuck for an answer.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full h-full bg-white py-16 px-7 xl:px-28">
        <h2 className="text-2xl xl:text-4xl font-medium text-primary-blue text-center">
          Early Tester Reviews
        </h2>
        <hr className="border-t-[5px] border-primary-green mt-3 w-[80%] sm:w-[35%] 3xl:w-[15%] mx-auto" />
        <div className="flex flex-col lg:flex-row items-center justify-center space-y-24 lg:space-y-0 lg:space-x-10 mt-28 lg:mt-40">
          <ReviewersCard
            imageUrl={Reviewer}
            name="John Doe"
            organizationName="XYZ"
            reviewStars={5}
            description="Navigating the different aspects of an HOA can be tricky. We simplify it for you. Gain confidence in every decision, with seasoned pros from law, accounting, landscaping, and more. 
"
          />
          <ReviewersCard
            imageUrl={Reviewer}
            name="John Doe"
            organizationName="XYZ"
            reviewStars={5}
            description="Navigating the different aspects of an HOA can be tricky. We simplify it for you. Gain confidence in every decision, with seasoned pros from law, accounting, landscaping, and more. 
"
          />
          <ReviewersCard
            imageUrl={Reviewer}
            name="John Doe"
            organizationName="XYZ"
            reviewStars={5}
            description="Navigating the different aspects of an HOA can be tricky. We simplify it for you. Gain confidence in every decision, with seasoned pros from law, accounting, landscaping, and more. 
"
          />
        </div>
      </div>

      <div className="w-full 3xl:w-[70%] mx-auto flex flex-col sm:flex-row items-center sm:space-x-5 lg:space-x-10 3xl:space-x-32 sm:px-10 2xl:px-28 py-16">
        <div className="w-full sm:w-1/2 order-2 sm:order-1 px-5 sm:px-0">
          <h3 className="text-primary-blue font-medium text-xl lg:text-3xl 3xl:text-6xl mt-5 lg:mt-0">
            About Us
          </h3>
          <hr className="border-t-[5px] border-primary-green mt-2" />
          <p className="text-primary-blue text-base sm:text-xs lg:text-base xl:text-xl 3xl:text-3xl font-light my-5">
            Drawing from our{" "}
            <span className="text-primary-green">30 years </span>
            of industry experience, Merit Management has navigated the complex
            world of HOAs and understands the hurdles you're up against. Good
            folks, like those in self-managed HOAs, trying their best but
            feeling lost. It's hard seeing communities, where people have put
            down roots and built memories, start to fade. And the most
            heart-wrenching part? Watching dedicated board members, many nearing
            their golden years, shouldering the weight of it all. They're trying
            to keep everything afloat, but without the right tools, it's like
            trying to fix a leak with duct tape.
          </p>
          <p className="text-primary-blue text-base sm:text-xs lg:text-base xl:text-xl 3xl:text-3xl font-light">
            That's why we created{" "}
            <span className="font-semibold">
              SMART - Self-Managed Association Resources & Training
            </span>
            . Think of us as a friendly neighbor lending a hand, sharing the
            secrets we've picked up from three decades in the business. And
            trust us, Merit Management has the 5-star Google reviews to back it
            up. With SMART, managing an HOA doesn't have to feel like an uphill
            battle. We've seen communities turn around, property values climb,
            and board members breathe easier. It's all about giving you the
            right tools and showing you how to use them. Because every community
            deserves to shine.
          </p>
        </div>
        <img
          src={homePageSix}
          alt=""
          className="w-full sm:w-1/2 object-cover order-1 sm:order-2 rounded-tl-[200px]"
        />
      </div>

      <div
        style={{ backgroundImage: `url(${homePageSeven})` }}
        className="max-w-screen lg:h-[390px] bg-cover px-5 py-10 lg:pt-16 lg:px-28 flex flex-col items-center justify-center"
      >
        <p className="text-white text-center text-xl lg:text-4xl">
          Pre-Register Now For Your{" "}
          <span className="font-bold">Free 30-Day Trial</span>
        </p>
        <Button
          onClick={() => {
            setOpenPreRegistrationModal(true);
            window.scrollTo(0, 0);
          }}
          innerText="PRE-REGISTER TODAY"
          extraClass="bg-primary-green text-primary-blue lg:!text-xl !text-base w-[70%] sm:w-[40%] xl:w-[25%] py-3 mt-10"
        />
      </div>
    </div>
  );
};

export default HomePage;
