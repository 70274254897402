import React from "react";
import Star from "../../assets/SVG/star";

const ReviewersCard = ({
  imageUrl,
  name,
  organizationName,
  reviewStars,
  description,
}) => {
  return (
    <div className="bg-white w-[300px] lg:max-w-[350px] h-[330px] lg:h-[380px] drop-shadow-2xl rounded-[10px] relative">
      <div className="absolute w-[110px] h-[110px] -top-14 left-1/2 transform -translate-x-1/2">
        <img src={imageUrl} alt="" />
      </div>
      <div className="flex flex-col space-y-1 items-center justify-center mt-20">
        <h4 className="text-xl lg:text-2xl text-primary-blue font-medium">
          {name}
        </h4>
        <p className="text-lg lg:text-xl text-primary-blue font-normal">
          Member of {organizationName}
        </p>
        <div className="flex items-center space-x-2">
          {Array.apply(0, Array(reviewStars)).map((star, index) => (
            <Star key={index} className="h-6 w-6 text-primary-green" />
          ))}
        </div>
        <p className="w-[80%] text-center pt-3 text-sm lg:text-base text-primary-blue font-light">
          {description}
        </p>
      </div>
    </div>
  );
};

export default ReviewersCard;
