import React from "react";
import AboutUsPage from "../../assets/images/aboutusPage.png";
import blueBackgroundWrapper from "../../assets/images/blueBackgroundWrapper.png";
import WavyLinesBackgroundTwo from "../../assets/images/wavyLinesBackgroundTwo.png";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router";

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <div className="max-w-screen">
      <div className="relative h-[40vh] sm:h-full w-full">
        <div>
          <img
            src={AboutUsPage}
            alt=""
            className="w-full h-[40vh] sm:h-full object-cover"
          />
          <img
            src={blueBackgroundWrapper}
            alt=""
            className="absolute top-0 w-full h-[40vh] sm:h-full"
          />
        </div>
        <div className="w-full sm:max-w-md text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-white font-semibold text-2xl sm:text-5xl 3xl:text-7xl">
            About Us
          </h1>
          <hr className="mx-auto mt-4 border-t-[5px] border-primary-green w-1/2 sm:w-full" />
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${WavyLinesBackgroundTwo})` }}
        className="w-full bg-cover"
      >
        <div className="px-5 sm:max-w-2xl lg:max-w-4xl mx-auto text-center mt-12">
          <h2 className="text-2xl lg:text-3xl font-medium text-primary-blue">
            Our Experience and Purpose
          </h2>
          <p className="mt-5 lg:mt-10 text-base lg:text-xl text-primary-blue font-light">
            Drawing from our{" "}
            <span className="text-primary-green">30 years </span> experience,
            Merit Management has navigated the complex world of HOAs and
            understands the hurdles you're up against. Good folks, like those in
            self-managed HOAs, trying their best but feeling lost. It's hard
            seeing communities, where people have put down roots and built
            memories, start to fade. And the most heart-wrenching part? Watching
            dedicated board members, many nearing their golden years,
            shouldering the weight of it all. They're trying to keep everything
            afloat, but without the right tools, it's like trying to fix a leak
            with duct tape.
          </p>
          <p className="mt-5 lg:mt-10 text-base lg:text-xl text-primary-blue font-light">
            That's why we created{" "}
            <span className="font-semibold">
              SMART - Self-Managed Association Resources & Training
            </span>
            . Think of us as a friendly neighbor lending a hand, sharing the
            secrets we've picked up from three decades in the business. And
            trust us, Merit Management has the 5-star Google reviews to back it
            up. With SMART, managing an HOA doesn't have to feel like an uphill
            battle. We've seen communities turn around, property values climb,
            and board members breathe easier. It's all about giving you the
            right tools and showing you how to use them. Because every community
            deserves to shine.
          </p>
        </div>
        <div className="flex flex-col items-center">
          <Button
            onClick={() => navigate("/contact")}
            innerText="CONTACT US"
            extraClass="w-[80%] sm:w-[40%] lg:w-[20%] py-3 mt-10 lg:mt-16 mb-20 lg:mb-28 bg-gradient-to-r from-primary-blue to-primary-green text-white !text-base lg:!text-xl !font-bold"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
