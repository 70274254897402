import React from "react";
import faqPage from "../../assets/images/faqPage.png";
import blueBackgroundWrapper from "../../assets/images/blueBackgroundWrapper.png";
import WavyLinesBackground from "../../assets/images/wavyLinesBackground.png";
import FaqCard from "../../components/FaqCard/FaqCard";

const FAQ = () => {
  return (
    <div className="max-w-screen">
      <div className="relative h-[40vh] sm:h-full w-full">
        <div>
          <img
            src={faqPage}
            alt=""
            className="w-full h-[40vh] sm:h-full object-cover"
          />
          <img
            src={blueBackgroundWrapper}
            alt=""
            className="absolute top-0 w-full h-[40vh] sm:h-full"
          />
        </div>
        <div className="w-full sm:max-w-md text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-white font-semibold text-2xl sm:text-5xl 3xl:text-7xl">
            F.A.Q.
          </h1>
          <hr className="mx-auto mt-4 border-t-[.3125rem] border-primary-green w-1/2 sm:w-full" />
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${WavyLinesBackground})` }}
        className="w-full bg-cover py-20 flex flex-col space-y-10 items-center justify-center"
      >
        <FaqCard
          heading="How user-friendly is the SMART platform?"
          questionDescription="The SMART platform is designed to be used even if you aren’t tech-savvy. We've ensured it's intuitive, easy to navigate, and comes with step-by-step guides. Plus, if you ever get stuck, our support team is just a click away."
        />
        <FaqCard
          heading="Who's behind the creation of SMART? Can we trust the content?"
          questionDescription="SMART was created by Merit Management in Tampa, FL, an HOA Management Company whose principals have over 30 years’ experience in community management. Our 5-Star Google Reviews back our reputation, and we've poured all our knowledge into making SMART a reliable resource for you."
        />
        <FaqCard
          heading="Does SMART provide templates and forms that are ready to use for our HOA?"
          questionDescription="Absolutely! SMART offers a range of templates and forms tailored to help you spend less time doing busy work and more time on work that makes a difference in your community."
        />
        <FaqCard
          heading="How often is the content in SMART updated?"
          questionDescription="SMART is a “living” entity that is being added to on a regular basis with new subjects, interviews, papers, and videos."
        />
        <FaqCard
          heading="Can we get customized training or resources specific to our community's needs?"
          questionDescription="While SMART provides a comprehensive set of resources suitable for most HOAs, we understand every community is unique. Reach out to us, and we'll discuss how we can tailor our service to suit your specific needs better."
        />
        <FaqCard
          heading="How does the live training work? Do we need to schedule in advance?"
          questionDescription="Our live training sessions are scheduled at regular intervals, and you can join any session that fits your calendar. If you have a specific topic in mind, let us know and we will try to get it scheduled."
        />
      </div>
    </div>
  );
};

export default FAQ;
