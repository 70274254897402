import React, { useState } from "react";
import logo from "../../assets/images/logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Hamburger from "../../assets/SVG/hamburger";
import Cancel from "../../assets/SVG/cancel";

const HamburgerMenuList = ({ open, setOpen }) => {
  const location = useLocation();

  return (
    <div className="absolute z-20 bg-white w-full">
      <div className="slide-down bg-white flex flex-col items-center space-y-5 mt-10 lg:mt-0 lg:hidden">
        <Link
          to="/"
          className="w-1/3 text-center text-primary-blue text-xl font-medium"
          onClick={() => setOpen(false)}
        >
          HOME
          {location?.pathname === "/" && (
            <hr className="border-b-[5px] mt-1 rounded-full w-full border-primary-green"></hr>
          )}
        </Link>
        <Link
          to="/pricing"
          className="w-1/3 text-center text-primary-blue text-xl font-medium"
          onClick={() => setOpen(false)}
        >
          PRICING
          {location?.pathname === "/pricing" && (
            <hr className="border-b-[5px] rounded-full mt-1 w-full border-primary-green"></hr>
          )}
        </Link>
        <Link
          to="/about-us"
          className="w-1/3 text-center text-primary-blue text-xl font-medium"
          onClick={() => setOpen(false)}
        >
          ABOUT US
          {location?.pathname === "/about-us" && (
            <hr className="border-b-[5px] rounded-full w-full mt-1 border-primary-green"></hr>
          )}
        </Link>
        <Link
          to="/faq"
          className="w-1/3 text-center text-primary-blue text-xl font-medium"
          onClick={() => setOpen(false)}
        >
          F.A.Q
          {location?.pathname === "/faq" && (
            <hr className="border-b-[5px] rounded-full w-full mt-1 border-primary-green"></hr>
          )}
        </Link>
        <Link
          to="/contact"
          className="w-1/3 text-center text-primary-blue text-xl font-medium"
          onClick={() => setOpen(false)}
        >
          CONTACT
          {location?.pathname === "/contact" && (
            <hr className="border-b-[5px] rounded-full w-full mt-1 border-primary-green"></hr>
          )}
        </Link>
        <button
          onClick={() => setOpen(false)}
          className="bg-white text-primary-blue font-medium text-xl border-[2px] border-primary-blue rounded-[10px] px-8 py-1.5 hover:bg-primary-blue hover:text-white"
        >
          LOGIN
        </button>
      </div>
      <hr className="mt-16 border-t-[5px] border-primary-green w-full"></hr>
    </div>
  );
};

const Header = () => {
  const navigate = useNavigate();
  const [openHamburger, setOpenHamburger] = useState(false);

  return (
    <div className="sticky top-0 z-20 bg-white lg:flex lg:items-center lg:justify-between px-0 lg:px-12 pt-5">
      <div className="flex justify-between items-center px-4 lg:px-0">
        <img
          onClick={() => navigate("/")}
          src={logo}
          alt=""
          className="h-[70px] xl:h-[100px] cursor-pointer"
        />
        {openHamburger ? (
          <Cancel
            onClick={() => setOpenHamburger(false)}
            className="lg:hidden w-10 h-10 text-primary-green"
          />
        ) : (
          <Hamburger
            onClick={() => setOpenHamburger(true)}
            className="lg:hidden w-8 h-6 text-primary-green"
          />
        )}
      </div>
      {openHamburger && (
        <HamburgerMenuList open={openHamburger} setOpen={setOpenHamburger} />
      )}
      <div className="hidden lg:flex lg:flex-row items-center lg:space-x-8">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive
              ? "text-primary-blue text-xl font-medium underline decoration-2 decoration-primary-green underline-offset-8 "
              : "text-primary-blue text-xl font-medium"
          }
        >
          HOME
        </NavLink>
        <NavLink
          to="/pricing"
          className={({ isActive }) =>
            isActive
              ? "text-primary-blue text-xl font-medium underline decoration-2 decoration-primary-green underline-offset-8 "
              : "text-primary-blue text-xl font-medium"
          }
        >
          PRICING
        </NavLink>
        <NavLink
          to="/about-us"
          className={({ isActive }) =>
            isActive
              ? "text-primary-blue text-xl font-medium underline decoration-2 decoration-primary-green underline-offset-8 "
              : "text-primary-blue text-xl font-medium"
          }
        >
          ABOUT US
        </NavLink>
        <NavLink
          to="/faq"
          className={({ isActive }) =>
            isActive
              ? "text-primary-blue text-xl font-medium underline decoration-2 decoration-primary-green underline-offset-8 "
              : "text-primary-blue text-xl font-medium"
          }
        >
          F.A.Q
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) =>
            isActive
              ? "text-primary-blue text-xl font-medium underline decoration-2 decoration-primary-green underline-offset-8 "
              : "text-primary-blue text-xl font-medium"
          }
        >
          CONTACT
        </NavLink>
        <button className="bg-white text-primary-blue font-medium text-xl border-[2px] border-primary-blue rounded-[10px] px-8 py-1.5 hover:bg-primary-blue hover:text-white">
          LOGIN
        </button>
      </div>
    </div>
  );
};

export default Header;
