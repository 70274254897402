import React, { useState } from "react";
import PricingPage from "../../assets/images/pricingPage.png";
import BlueBackgroundWrapper from "../../assets/images/blueBackgroundWrapper.png";
import WavyLinesBackground from "../../assets/images/wavyLinesBackground.png";
import PricingCard from "../../components/PricingCard/PricingCard";
import PreRegistrationModal from "../../components/PreRegisterationModal/PreRegisterationModal";

const Pricing = () => {
  const [openPreRegistrationModal, setOpenPreRegistrationModal] =
    useState(false);

  return (
    <div className="max-w-screen relative">
      {openPreRegistrationModal && (
        <PreRegistrationModal setOpen={setOpenPreRegistrationModal} />
      )}
      <div className="relative h-[40vh] sm:h-full w-full">
        <div>
          <img
            src={PricingPage}
            alt=""
            className="w-full h-[40vh] sm:h-full object-cover"
          />
          <img
            src={BlueBackgroundWrapper}
            alt=""
            className="absolute top-0 w-full h-[40vh] sm:h-full"
          />
        </div>
        <div className="w-full sm:max-w-lg text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-white font-semibold text-2xl sm:text-5xl 3xl:text-7xl">
            Time to get SMART
          </h1>
          <hr className="mx-auto mt-4 border-t-[.3125rem] border-primary-green w-1/2 sm:w-full" />
          <h2 className="text-xl sm:text-3xl font-normal mt-5 text-white">
            How much is your peace of mind worth?
          </h2>
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${WavyLinesBackground})` }}
        className="w-full py-20 flex flex-col items-center"
      >
        {/******************************MOBILE VIEW CARDS***************************************/}
        <div className="lg:hidden bg-white w-full mx-1 mb-1 flex flex-col space-y-10 items-center">
          <div className="w-[90%] sm:w-[50%] border-[4px] border-green-800 shadow-xl rounded-lg">
            <div className="-ml-0.5 -mt-[0.5px] rounded bg-gradient-to-r from-primary-blue to-primary-green text-center py-4">
              <h3 className="text-xl text-white font-normal">
                Single User Package
              </h3>
            </div>
            <PricingCard
              packageType="Single User Package"
              packageDescription="A single board member may access the site for a year. They are free to share what they learn with the rest of the Board of Directors, keeping them updated and current for the next twelve months."
              packageBenefits={[
                "Access to articles, white papers, videos, and interviews on essential HOA topics.",
                "Access to forms and templates used by management companies.",
                "Search function allowing you to search hundreds of topics easily.",
                "Insights from outside experts in law, accounting, landscaping, insurance, and more.",
                "Regular 20-30 minute Zoom sessions with industry experts, followed by Q&A.",
                "Free 3-Month trial of violation management software",
                "Free 30 minute consultation with TheHOACoach.com online management expert on the topic of your choice.",
              ]}
              price={319}
              renewalPrice={149}
              handleButtonClick={() => {
                setOpenPreRegistrationModal(true);
                window.scrollTo(0, 0);
              }}
            />
          </div>
          <div className="w-[90%] sm:w-[50%] border-[4px] border-green-800 shadow-xl rounded-lg">
            <div className="-ml-0.5 -mt-[0.5px] rounded bg-gradient-to-r from-primary-blue to-primary-green text-center py-4">
              <h3 className="text-xl text-white font-normal">Board Package</h3>
            </div>
            <PricingCard
              packageType="Board Package"
              packageDescription="Up to five board members from the same association may access the site for the following year keeping themselves updated and current for the next twelve months."
              packageBenefits={[
                "Access to articles, white papers, videos, and interviews on essential HOA topics.",
                "Access to forms and templates used by management companies.",
                "Search function allowing you to search hundreds of topics easily.",
                "Insights from outside experts in law, accounting, landscaping, insurance, and more.",
                "Regular 20-30 minute Zoom sessions with industry experts, followed by Q&A.",
                "Free 3-Month trial of violation management software",
                "Free 30 minute consultation with TheHOACoach.com online management expert on the topic of your choice.",
              ]}
              price={399}
              renewalPrice={199}
              handleButtonClick={() => {
                setOpenPreRegistrationModal(true);
                window.scrollTo(0, 0);
              }}
            />
          </div>
        </div>
        <div className="hidden lg:inline-block max-w-4xl mx-auto">
          <div className="relative grid grid-cols-2 rounded-t-[20px] bg-gradient-to-r from-primary-blue to-primary-green text-center py-4">
            <h3 className="text-3xl text-white font-normal">
              Single User Package
            </h3>
            <div className="absolute left-1/2 w-[4px] h-full bg-white"></div>
            <h3 className="text-3xl text-white font-normal">Board Package</h3>
          </div>
          <div className="relative flex justify-center rounded-b-[10px] bg-gradient-to-br from-white via-primary-green to-primary-blue">
            <div className="bg-white w-full mx-1 mb-1 flex">
              <PricingCard
                packageType="Single User Package"
                packageDescription="A single board member may access the site for a year. They are free to share what they learn with the rest of the Board of Directors, keeping them updated and current for the next twelve months."
                packageBenefits={[
                  "Access to articles, white papers, videos, and interviews on essential HOA topics.",
                  "Access to forms and templates used by management companies.",
                  "Search function allowing you to search hundreds of topics easily.",
                  "Insights from outside experts in law, accounting, landscaping, insurance, and more.",
                  "Regular 20-30 minute Zoom sessions with industry experts, followed by Q&A.",
                  "Free 3-Month trial of violation management software",
                  "Free 30 minute consultation with TheHOACoach.com online management expert on the topic of your choice.",
                ]}
                price={319}
                renewalPrice={149}
                handleButtonClick={() => {
                  setOpenPreRegistrationModal(true);
                  window.scrollTo(0, 0);
                }}
              />
              <div className="absolute left-1/2 w-[4px] h-[99.6%] bg-gradient-to-b from-white to-primary-green"></div>
              <PricingCard
                packageType="Board Package"
                packageDescription="Up to five board members from the same association may access the site for the following year keeping themselves updated and current for the next twelve months."
                packageBenefits={[
                  "Access to articles, white papers, videos, and interviews on essential HOA topics.",
                  "Access to forms and templates used by management companies.",
                  "Search function allowing you to search hundreds of topics easily.",
                  "Insights from outside experts in law, accounting, landscaping, insurance, and more.",
                  "Regular 20-30 minute Zoom sessions with industry experts, followed by Q&A.",
                  "Free 3-Month trial of violation management software",
                  "Free 30 minute consultation with TheHOACoach.com online management expert on the topic of your choice.",
                ]}
                price={399}
                renewalPrice={199}
                handleButtonClick={() => {
                  setOpenPreRegistrationModal(true);
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
