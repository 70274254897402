import React, { useState } from "react";
import classes from "./modal.module.css";
import Cross from "../../assets/SVG/cancel";
import RegistrationBackground from "../../assets/images/registration.jpg";
import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import Button from "../Button/Button";
import axios from "axios";

const PreRegistrationModal = ({ setOpen }) => {
  const [preRegistrationValues, setPreRegistrationValues] = useState({
    name: "",
    email: "",
    associationLegalName: "",
    managementType: "",
  });

  const changeHandler = (e) => {
    setPreRegistrationValues({
      ...preRegistrationValues,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async () => {
    console.log(preRegistrationValues);
    //call the lambda function here
    try {
      const res = await axios.post(
        "https://bqxajrbrrin25t3rfb2cgcik240bmxvy.lambda-url.us-east-1.on.aws/",
        { formType: "pre-registration", ...preRegistrationValues }
      );
      console.log("contact us response", res);
      if (res.status === 200) {
        setPreRegistrationValues({
          name: "",
          email: "",
          associationLegalName: "",
          managementType: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.modalWrapper}>
      <div className={classes.modal}>
        <div className="relative">
          <Cross
            onClick={() => setOpen(false)}
            className="h-6 w-6 text-black hover:text-red-500 cursor-pointer absolute right-4 top-3"
          />
        </div>
        <div className="flex lg:space-x-10 pt-16 pb-10 px-7 w-full h-full">
          <div className="hidden lg:inline-flex lg:w-[40%]">
            <img src={RegistrationBackground} alt="" className="object-cover" />
          </div>
          <div className="w-full lg:w-[60%]">
            <h3 className="text-xs sm:text-sm lg:text-base text-center font-medium text-primary-blue">
              Thank you for your pre-registration. We are excited for you to see
              what we are building and look forward to sharing it with you soon.
              You will receive an auto-response to confirm this registration and
              then we absolutely promise that you will not receive any
              additional email from us unless it is regarding the launch
              schedule.
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-5 gap-y-7 mt-5">
              <div>
                <p className="mb-1 lg:mb-3 text-sm lg:text-base text-primary-blue xl:leading-[27px] font-normal">
                  Name
                </p>
                <Input
                  type="text"
                  name="name"
                  onChange={(e) => changeHandler(e)}
                  id="name"
                  value={preRegistrationValues.name}
                  placeholder="Enter your name"
                />
              </div>
              <div>
                <p className="mb-1 lg:mb-3 text-sm lg:text-base text-primary-blue xl:leading-[27px] font-normal">
                  Email
                </p>
                <Input
                  type="text"
                  name="email"
                  value={preRegistrationValues.email}
                  onChange={(e) => changeHandler(e)}
                  id="email"
                  placeholder="Enter your email id"
                />
              </div>
              <div>
                <p className="mb-1 lg:mb-3 text-sm lg:text-base text-primary-blue xl:leading-[27px] font-normal">
                  Full Legal Name Of Association
                </p>
                <Input
                  type="text"
                  name="associationLegalName"
                  value={preRegistrationValues.associationLegalName}
                  onChange={(e) => changeHandler(e)}
                  id="associationLegalName"
                  placeholder="Enter full legal name of association"
                />
              </div>
              <div>
                <p className="mb-1 lg:mb-3 text-sm lg:text-base text-primary-blue xl:leading-[27px] font-normal">
                  Type Of Management
                </p>
                <Dropdown
                  dropdownList={[
                    { title: "Self Managed", id: "SM" },
                    { title: "Professionally Managed", id: "PM" },
                  ]}
                  placeholder="Select Management Type"
                  dropdownCallback={(value) => {
                    setPreRegistrationValues({
                      ...preRegistrationValues,
                      managementType: value,
                    });
                  }}
                  defaultValue={preRegistrationValues?.managementType}
                />
              </div>
              <Button
                onClick={() => {
                  setOpen(false);
                  submitHandler();
                }}
                innerText="Submit"
                type="submit"
                extraClass={`sm:col-span-2 w-[80%] mx-auto text-center h-[45px] text-sm lg:text-base xl:text-lg text-white font-medium rounded-full bg-gradient-to-r from-primary-blue to-primary-green text-white`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreRegistrationModal;
