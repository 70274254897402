import React from "react";

const Clock = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "w-9 h-10"}
      fill="none"
      viewBox="0 0 36 41"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.854 20.5c0-10.143 7.138-18.365 15.942-18.365 8.805 0 15.943 8.223 15.943 18.365 0 10.142-7.138 18.365-15.943 18.365-8.804 0-15.942-8.223-15.942-18.365zM17.796 4.698c-7.576 0-13.718 7.075-13.718 15.802s6.142 15.802 13.718 15.802c7.577 0 13.718-7.075 13.718-15.802S25.373 4.698 17.796 4.698z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M17.802 8.969c.615 0 1.113.574 1.113 1.281l-.001 9.727 5.961 6.867c.435.5.435 1.312 0 1.812-.434.5-1.138.5-1.573 0l-6.287-7.242a1.386 1.386 0 01-.326-.907l.001-10.257c0-.708.498-1.281 1.112-1.281z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Clock;
