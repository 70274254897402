import React from "react";

const Star = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 25 25"
      className={className ? className : "h-6 w-6"}
    >
      <g clipPath="url(#clip0_77_472)">
        <path
          fill="currentColor"
          d="M13.707 1.296l2.82 5.714c.196.397.575.673 1.014.736l6.305.916c1.105.161 1.545 1.518.746 2.296l-4.562 4.448c-.317.309-.462.755-.387 1.191l1.077 6.28c.189 1.1-.966 1.938-1.954 1.42l-5.64-2.965a1.348 1.348 0 00-1.252 0l-5.64 2.965c-.987.519-2.142-.32-1.953-1.42l1.077-6.28a1.346 1.346 0 00-.387-1.191L.408 10.959c-.799-.78-.358-2.136.747-2.297l6.305-.916a1.346 1.346 0 001.014-.736l2.82-5.714c.493-1 1.92-1 2.413 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_77_472">
          <path fill="#fff" d="M0 0H25V25H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Star;
