import React from "react";

const Hamburger = ({ className, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 41 30"
      className={className ? className : "w-10 h-8"}
      {...rest}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="5"
        d="M3 3h35M3 15h35M3 27h35"
      ></path>
    </svg>
  );
};

export default Hamburger;
