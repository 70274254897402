import React, { useState } from "react";
import contactPage from "../../assets/images/contactPage.png";
import blueBackgroundWrapper from "../../assets/images/blueBackgroundWrapper.png";
import WavyLinesBackground from "../../assets/images/wavyLinesBackground.png";
import contactForm from "../../assets/images/contactForm.png";
import Button from "../../components/Button/Button";
import axios from "axios";

const ContactUs = () => {
  const [contactValues, setContactValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    communityName: "",
    country: "",
    state: "",
    message: "",
  });

  const changeHandler = (e) => {
    setContactValues({ ...contactValues, [e.target.name]: e.target.value });
  };

  const submitHandler = async () => {
    console.log(contactValues);
    //call the lambda function here
    const postData = { formType: "contact-us", ...contactValues };
    console.log("postData", typeof postData);
    try {
      const res = await axios.post(
        "https://bqxajrbrrin25t3rfb2cgcik240bmxvy.lambda-url.us-east-1.on.aws/",
        postData
      );
      console.log("contact us response", res);
      if (res.status === 200) {
        setContactValues({
          firstName: "",
          lastName: "",
          email: "",
          communityName: "",
          country: "",
          state: "",
          message: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("contact values0", contactValues);
  return (
    <div className="max-w-screen">
      <div className="relative h-[40vh] sm:h-full w-full">
        <div>
          <img
            src={contactPage}
            alt=""
            className="w-full h-[40vh] sm:h-full object-cover"
          />
          <img
            src={blueBackgroundWrapper}
            alt=""
            className="absolute top-0 w-full h-[40vh] sm:h-full"
          />
        </div>
        <div className="w-full sm:max-w-md text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <h1 className="text-white font-semibold text-2xl sm:text-5xl 3xl:text-7xl">
            Let’s Connect
          </h1>
          <hr className="mx-auto mt-4 border-t-[5px] border-primary-green w-1/2 sm:w-full" />
        </div>
      </div>

      <div
        style={{ backgroundImage: `url(${WavyLinesBackground})` }}
        className="w-full bg-cover xl:py-20 flex flex-col items-center"
      >
        <div className="w-full xl:w-auto flex items-center xl:h-[756px]">
          <div className="w-full xl:w-auto xl:pl-1 xl:py-1 xl:bg-gradient-to-br from-white via-primary-green to-primary-blue rounded-bl-[10px] h-full">
            <div className="w-full sm:w-[70%] lg:w-[50%] xl:w-auto mx-auto px-5 xl:px-10 bg-white mt-7 xl:mt-0  flex flex-col items-center justify-center h-full">
              <div className="text-center">
                <h2 className="text-2xl xl:text-3xl font-medium text-primary-blue">
                  Contact Us Today
                </h2>
                <hr className="border-t-[5px] border-primary-green my-1.5 xl:my-3 w-[55%] mx-auto" />
                <p className="text-base xl:text-xl font-medium text-primary-blue">
                  Send us your message and let’s connect.
                </p>
              </div>
              <div className="w-full grid grid-cols-2 gap-3 xl:gap-5 mt-10">
                <input
                  name="firstName"
                  type="text"
                  onChange={(e) => changeHandler(e)}
                  value={contactValues.firstName}
                  placeholder="First Name"
                  className="focus:outline-none border border-primary-blue rounded-[10px] p-3 text-sm xl:text-xl font-normal text-primary-blue placeholder:text-sm xl:placeholder:text-xl placeholder:text-primary-blue"
                />
                <input
                  name="lastName"
                  type="text"
                  value={contactValues.lastName}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Last Name"
                  className="focus:outline-none border border-primary-blue rounded-[10px] p-3 text-sm xl:text-xl font-normal text-primary-blue placeholder:text-sm xl:placeholder:text-xl placeholder:text-primary-blue"
                />
                <input
                  name="email"
                  type="email"
                  value={contactValues.email}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Email"
                  className="col-span-2 focus:outline-none border border-primary-blue rounded-[10px] p-3 text-sm xl:text-xl font-normal text-primary-blue placeholder:text-sm xl:placeholder:text-xl placeholder:text-primary-blue"
                />
                <input
                  name="communityName"
                  type="text"
                  value={contactValues.communityName}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Community Name"
                  className="focus:outline-none border border-primary-blue rounded-[10px] p-3 text-sm xl:text-xl font-normal text-primary-blue placeholder:text-sm xl:placeholder:text-xl placeholder:text-primary-blue"
                />
                {/* <input
                  name="country"
                  type="text"
                  value={contactValues.country}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Country"
                  className="focus:outline-none border border-primary-blue rounded-[10px] p-3 text-sm xl:text-xl font-normal text-primary-blue placeholder:text-sm xl:placeholder:text-xl placeholder:text-primary-blue"
                /> */}
                <input
                  name="state"
                  type="text"
                  value={contactValues.state}
                  onChange={(e) => changeHandler(e)}
                  placeholder="State"
                  className="focus:outline-none border border-primary-blue rounded-[10px] p-3 text-sm xl:text-xl font-normal text-primary-blue placeholder:text-sm xl:placeholder:text-xl placeholder:text-primary-blue"
                />
                <textarea
                  type="text"
                  name="message"
                  value={contactValues.message}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Your Message"
                  style={{ resize: "none" }}
                  className="focus:outline-none h-[125px] col-span-2 border border-primary-blue rounded-[10px] p-3 text-sm xl:text-xl font-normal text-primary-blue placeholder:text-sm xl:placeholder:text-xl placeholder:text-primary-blue"
                />
              </div>
              <div className="w-full flex flex-col items-center">
                <Button
                  onClick={() => submitHandler()}
                  innerText="SUBMIT"
                  extraClass="bg-primary-green text-primary-blue w-[80%] xl:w-[50%] py-3 my-5 sm:my-10 xl:mt-10 mx-auto"
                />
              </div>
            </div>
          </div>
          <div className="hidden xl:inline-flex">
            <img src={contactForm} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
