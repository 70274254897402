import React, { useState } from "react";
import Button from "../Button/Button";
import Cancel from "../../assets/SVG/cancel";
import axios from "axios";
const Feedback = () => {
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [feedbackValues, setFeedbackValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    communityName: "",
    country: "",
    state: "",
    message: "",
  });

  const changeHandler = (e) => {
    setFeedbackValues({ ...feedbackValues, [e.target.name]: e.target.value });
  };

  const submitHandler = async () => {
    console.log(feedbackValues);
    //call the lambda function here
    try {
      const res = await axios.post(
        "https://bqxajrbrrin25t3rfb2cgcik240bmxvy.lambda-url.us-east-1.on.aws/",
        { formType: "feedback", ...feedbackValues }
      );
      console.log("contact us response", res);
      if (res.status === 200) {
        setFeedbackValues({
          firstName: "",
          lastName: "",
          email: "",
          communityName: "",
          country: "",
          state: "",
          message: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {!feedbackOpen && (
        <Button
          onClick={() => setFeedbackOpen(true)}
          innerText="FEEDBACK"
          extraClass="!rounded-none rounded-b-[10px] bg-primary-green text-primary-blue text-base sm:text-xl w-[130px] sm:w-[200px] z-40 fixed top-[350px] 2xl:top-[380px] -right-[43px] sm:-right-[70px] py-3 sm:py-4 rotate-90"
        />
      )}
      {feedbackOpen && (
        <div className="w-[98%] sm:w-full sm:max-w-2xl z-20 max-h-fit flex fixed top-[200px] sm:top-[250px] 2xl:top-[310px] -right-5">
          <div className="w-full">
            <div className="flex w-full h-full">
              <div className="rounded-l-[10px] w-[15%] sm:w-[10%] h-full flex flex-col items-center justify-center bg-primary-blue text-xl font-bold text-white">
                <p className="rotate-90">FEEDBACK</p>
              </div>
              <div className="relative w-[85%] sm:w-[90%] bg-primary-blue opacity-90 pl-5 pr-10 py-1">
                <h4 className="text-center my-5 text-md font-medium text-white">
                  Send us a message and let’s have a chat.
                </h4>
                <Cancel
                  onClick={() => setFeedbackOpen(false)}
                  className="text-white cursor-pointer h-5 w-5 absolute right-7 top-2"
                />
                <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                  <input
                    name="firstName"
                    onChange={(e) => changeHandler(e)}
                    value={feedbackValues.firstName}
                    type="text"
                    placeholder="First Name"
                    className="focus:outline-none bg-transparent border border-white rounded-[10px] p-2 text-sm font-normal text-white placeholder:text-white placeholder:text-xs sm:placeholder:text-sm"
                  />
                  <input
                    name="lastName"
                    onChange={(e) => changeHandler(e)}
                    value={feedbackValues.lastName}
                    type="text"
                    placeholder="Last Name"
                    className="focus:outline-none bg-transparent border border-white rounded-[10px] p-2 text-sm font-normal text-white placeholder:text-white placeholder:text-xs sm:placeholder:text-sm"
                  />
                  <input
                    name="email"
                    onChange={(e) => changeHandler(e)}
                    value={feedbackValues.email}
                    type="email"
                    placeholder="Email"
                    className="focus:outline-none bg-transparent border border-white rounded-[10px] p-2 text-sm font-normal text-white placeholder:text-white placeholder:text-xs sm:placeholder:text-sm"
                  />
                  <input
                    name="state"
                    onChange={(e) => changeHandler(e)}
                    value={feedbackValues.state}
                    type="text"
                    placeholder="State"
                    className="focus:outline-none bg-transparent border border-white rounded-[10px] p-2 text-sm font-normal text-white placeholder:text-white placeholder:text-xs sm:placeholder:text-sm"
                  />
                  <input
                    name="communityName"
                    onChange={(e) => changeHandler(e)}
                    value={feedbackValues.communityName}
                    type="text"
                    placeholder="Community Name"
                    className="col-span-2 focus:outline-none bg-transparent border border-white rounded-[10px] p-2 text-sm font-normal text-white placeholder:text-white placeholder:text-xs sm:placeholder:text-sm"
                  />
                  {/* <input
                    name="country"
                    onChange={(e) => changeHandler(e)}
                    value={feedbackValues.country}
                    type="text"
                    placeholder="Country"
                    className="focus:outline-none bg-transparent border border-white rounded-[10px] p-2 text-sm font-normal text-white placeholder:text-white placeholder:text-xs sm:placeholder:text-sm"
                  /> */}

                  <textarea
                    name="message"
                    onChange={(e) => changeHandler(e)}
                    value={feedbackValues.message}
                    type="text"
                    placeholder="Your Message"
                    style={{ resize: "none" }}
                    className="mb-20 focus:outline-none col-span-2 sm:col-span-3 bg-transparent border border-white rounded-[10px] p-2 text-sm font-normal text-white placeholder:text-white placeholder:text-xs sm:placeholder:text-sm"
                  />
                </div>
              </div>
              <div className="absolute bottom-5 col-span-2 sm:col-span-3 w-full flex flex-col items-center">
                <Button
                  onClick={() => submitHandler()}
                  type="submit"
                  innerText="SUBMIT"
                  extraClass="py-2 w-[50%] bg-primary-green text-primary-blue"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feedback;
