import React from "react";
import InformationCircle from "../../assets/SVG/information-circle";
import Check from "../../assets/SVG/check";
import Button from "../Button/Button";

const PricingCard = ({
  packageType,
  packageDescription,
  packageBenefits,
  price,
  renewalPrice,
  handleButtonClick,
}) => {
  return (
    <div className="bg-white max-w-md">
      <div className="flex flex-col items-center mx-7">
        <h4 className="my-5 text-xl sm:text-2xl font-medium text-primary-blue">
          Annual Membership
        </h4>
        <p className="h-auto xl:h-[150px] text-sm sm:text-base xl:text-xl text-primary-blue font-light">
          <InformationCircle className="text-primary-blue h-6 w-6 inline-block -mt-1" />{" "}
          {packageDescription}
        </p>
        <div className="mt-5">
          {packageBenefits.map((benefit, index) => (
            <div key={index} className="flex items-center space-x-3 mb-3">
              <Check className="h-3 w-3 sm:h-5 sm:w-5 text-primary-green" />
              <p className="w-[90%] text-xs sm:text-base xl:text-xl font-light text-primary-blue">
                {benefit}
              </p>
            </div>
          ))}
        </div>
        <div className="my-3 sm:my-7">
          <p className="text-5xl xl:text-6xl text-primary-blue font-semibold">
            ${price}{" "}
            <span className="text-xl xl:text-2xl font-normal">
              / First Year
            </span>
          </p>
          <p className="text-xs xl:text-lg text-primary-blue font-normal">
            *Renewals are just ${renewalPrice} per year
          </p>
        </div>
        <Button
          onClick={handleButtonClick}
          innerText="PURCHASE PLAN"
          extraClass="w-full py-3.5 my-7 sm:my-0 sm:mb-10 bg-gradient-to-r from-primary-blue to-primary-green text-white !text-base lg:!text-xl !font-bold"
        />
      </div>
    </div>
  );
};

export default PricingCard;
