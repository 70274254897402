import React from "react";

const Button = ({ type, innerText, extraClass, ...rest }) => {
  return (
    <button
      style={{ opacity: 1 }}
      type={type}
      className={`rounded-[10px] font-bold ${extraClass}`}
      {...rest}
    >
      <span>{innerText}</span>
    </button>
  );
};

export default Button;
