import React from "react";
import QuestionMarkCircle from "../../assets/SVG/question-mark-circle";

const FaqCard = ({ heading, questionDescription }) => {
  return (
    <div className="drop-shadow-2xl w-[90%] lg:w-[75%] 2xl:w-[65%] 3xl:w-[50%] bg-white rounded-[10px]">
      <div className="py-3 sm:py-5 px-5 xl:px-10 bg-gradient-to-r from-primary-blue to-primary-green rounded-[10px] flex items-center space-x-3 sm:space-x-5">
        <div className="h-6 w-6 sm:h-8 sm:w-8">
          <QuestionMarkCircle className="text-white h-6 w-6 sm:h-8 sm:w-8" />
        </div>
        <h1 className="text-base sm:text-lg xl:text-2xl 3xl:text-3xl font-normal text-white">
          {heading}
        </h1>
      </div>
      <p className="px-5 xl:px-10 py-5 3xl:pt-5 3xl:pb-10 text-sm sm:text-base xl:text-xl 3x:text-2xl text-black font-normal">
        {questionDescription}
      </p>
    </div>
  );
};

export default FaqCard;
