import React from "react";
import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-white w-full px-4 py-5 xl:px-12 xl:py-10 flex flex-col sm:flex-row space-y-5 sm:space-y-0 justify-between border-t border-primary-green">
      <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-10">
        <img
          onClick={() => navigate("/")}
          src={logo}
          alt=""
          className="w-[150px] h-[90px] xl:w-[180px] xl:h-[120px] cursor-pointer"
        />
        <div className="w-full lg:max-w-lg">
          <p className="text-primary-blue text-base sm:text-sm xl:text-xl font-light">
            SMART was founded by Merit Management, an HOA management company
            with 30 years of industry experience under their belt. We've seen
            the challenges self-managed HOAs face. It's tough watching dedicated
            board members struggle to keep communities thriving.
          </p>
          <div className="hidden mt-2 text-primary-blue font-light text-sm xl:text-base sm:inline-flex items-center space-x-5">
            <p>2023 All Rights Reserved</p>
            <p>Terms of Use | Privacy Policy</p>
          </div>
        </div>
      </div>

      <div className="flex space-x-10">
        <div className="flex flex-col space-y-1">
          <Link
            to="/pricing"
            className="text-lg sm:text-base xl:text-xl font-light text-primary-blue"
          >
            Pricing
          </Link>
          <Link
            to="/about-us"
            className="text-lg sm:text-base xl:text-xl font-light text-primary-blue"
          >
            About Us
          </Link>
          <Link
            to="/faq"
            className="text-lg sm:text-base xl:text-xl font-light text-primary-blue"
          >
            F.A.Q.
          </Link>
          <Link
            to="/contact"
            className="text-lg sm:text-base xl:text-xl font-light text-primary-blue"
          >
            Contact
          </Link>
        </div>
        <div className="flex flex-col space-y-1">
          <Link
            to="/privacy-policy"
            className="text-lg sm:text-base xl:text-xl font-light text-primary-blue"
          >
            Privacy Policy
          </Link>
          <Link
            to="/t&c"
            className="text-lg sm:text-base xl:text-xl font-light text-primary-blue"
          >
            Terms & Conditions
          </Link>
        </div>
      </div>
      <div className="text-xs sm:hidden text-primary-blue font-light flex items-center justify-between">
        <p>2023 All Rights Reserved</p>
        <p>Terms of Use | Privacy Policy</p>
      </div>
    </div>
  );
};

export default Footer;
